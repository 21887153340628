import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './SiteFooter.scss';

function SiteFooter() {
    return (
        <Router>
            <footer className='border-top footer text-muted'>
                <div className='container-fluid'>
                    <div className='row justify-content-center'>
                        <div className='col-8'>
                Copyright Commonwealth of Australia {new Date().getFullYear()} - AG.FDRR.Register - V {window.__RUNTIME_CONFIG__.REACT_APP_BUILD_VERSION}
                        </div>
                    </div>
                </div>
            </footer>
        </Router>
    );
}

export default SiteFooter;
