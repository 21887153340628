import api from 'services/Api';
import { IBannerText } from './flairDatatypes';

class ReferenceDataApi {
  // API call - getAllBannerTexts
  async getAllBannerTexts() {
    return api.get<null, IBannerText[]>('bannertexts');
  }
}

export default ReferenceDataApi;
