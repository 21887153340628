import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faReply,
  faBars,
  faTrashAlt,
  faCaretUp,
  faCaretDown,
  faBomb,
  faAngleRight,
  faExclamationTriangle,
  faSync,
  faCheckCircle,
  faAngleDown,
  faAngleUp,
  faSlidersH,
  faWindowClose,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';

export const trashIcon = () => {
  return <FontAwesomeIcon color='black' icon={faTrashAlt} />;
};

export const chevronUpIcon = () => {
  return <FontAwesomeIcon icon={faCaretUp} />;
};

export const chevronDownIcon = () => {
  return <FontAwesomeIcon icon={faCaretDown} />;
};

export const angleUpIcon = () => {
  return <FontAwesomeIcon icon={faAngleUp} />;
};

export const angleDownIcon = () => {
  return <FontAwesomeIcon icon={faAngleDown} />;
};

export const bombIcon = () => {
  return <FontAwesomeIcon color='black' icon={faBomb} />;
};

export const angleRightIcon = () => {
  return <FontAwesomeIcon icon={faAngleRight} />;
};

export const exclamationIcon = () => {
  return <FontAwesomeIcon icon={faExclamationTriangle} size='1x' />;
};

export const spinnerIcon = () => {
  return <FontAwesomeIcon icon={faSync} spin={true} fixedWidth={true} />;
};

export const checkCircleIcon = () => {
  return <FontAwesomeIcon icon={faCheckCircle} />;
};

export const backToIcon = () => {
  return <FontAwesomeIcon title='' color='grey' icon={faReply} />;
};

export const hamburgerIcon = () => {
  return <FontAwesomeIcon color='black' icon={faBars} />;
};

export const filterIcon = () => {
  return <FontAwesomeIcon color='white' icon={faSlidersH} />;
};

export const tickIcon = () => {
  return <FontAwesomeIcon color='white' icon={faCheckCircle} />;
};

export const closeIcon = () => {
  return <FontAwesomeIcon color='white' icon={faWindowClose} />;
};

export const externalLinkIcon = () => {
  return <FontAwesomeIcon icon={faExternalLinkAlt} />;
};
