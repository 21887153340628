import React from 'react';
import crestImage from "images/crest.png";

const SiteLogo = () => {
    return (
        <div className='navbar-brand'>
            <a className='text-center' href='/'>
                <img src={`${crestImage}`} style={{ height: '48px' }} alt='Attorney-General Logo navigates back to dashboard' />
            </a>
        </div>
    );
};

export default SiteLogo;
