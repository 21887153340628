export function ParseBool(val: string | boolean | null): boolean {
  return val === '1' || val === 'yes' || val === true || val === 'true';
}

export function DisplayBool(val: string | boolean | null): string {
  return val === '1' || val === 'yes' || val === true || val === 'true'
    ? 'Yes'
    : 'No';
}

// Scroll window to top ofscreen
export function ScrollToTop(): void {
  window?.scrollTo(0, 0);
}

// sleep for N milliseconds, tip: 1000ms=1second
export const Sleep = (time: number | undefined) =>
  new Promise((acc) => setTimeout(acc, time));

// Used for optional fields on the display page
export const DisplayFieldValueOrDefaultText = (value?: string): string => {
  if (value && value.length > 0) {
    return value;
  }
  return ''; // '(Not provided)';
};

// avoid javascript: based injection attacks
export function validateAndSecureURL(url: string): string {
  if (url === undefined || url === '' || url === '#') {
    return '';
  } else {
    if (url.startsWith('undefined') || url.startsWith('/')) {
      // eslint-disable-next-line no-script-url
      return url.toLowerCase().replace('javascript:', '/javascript/:/');
    } else {
      const parsed = new URL(url);
      if (['https:', 'http:', 'mailto:'].includes(parsed.protocol)) {
        // eslint-disable-next-line no-script-url
        return url.toLowerCase().replace('javascript:', '/javascript/:/');
      } else {
        // not valid
        return '';
      }
    }
  }
}
