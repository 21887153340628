import React, { Suspense } from 'react';
import { Switch, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import routes from '../../Routes';
import { StaticContext } from 'react-router';
import { Fade } from 'react-bootstrap';

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse' />
  </div>
);

const Content = () => {
  return (
    <main className='c-main p-0'>
      <div className='pt-0 container-fluid'>
        <ToastContainer />
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    // name={route.name} // property not found - index.d.ts is called from ts4.0 for my other installation but not this one, need to yarn add @types/react-router I think
                    // tslint:disable-next-line: jsx-no-lambda
                    render={(
                      props: JSX.IntrinsicAttributes &
                        RouteComponentProps<
                          { id?: string | undefined },
                          StaticContext,
                          unknown
                        >
                    ) => (
                      <Fade>
                        <route.component {...props} />
                      </Fade>
                    )}
                  />
                )
              );
            })}
            <Redirect from='/' to='/search' />
          </Switch>
        </Suspense>
      </div>
    </main>
  );
};

export default Content;
