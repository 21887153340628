import { toast, Slide } from 'react-toastify';

export const errorToast = (
  message,
  autoClose = false,
  optionOverwrites = null
) => {
  toast.error(message, {
    position: 'bottom-center',
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    transition: Slide,
    ...optionOverwrites,
  });
};

export const successToast = (
  message,
  autoClose = 5000,
  optionOverwrites = null
) => {
  toast.success(message, {
    position: 'bottom-center',
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    transition: Slide,
    ...optionOverwrites,
  });
};
