import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { hot } from 'react-hot-loader';
import Layout from './layout/site/Layout';
import './styles/_buttons.scss';
import './styles/_forms.scss';
import './styles/_layout.scss';
import './styles/_variables.scss'; // import this last as it includes the bootstrap styles that are overriden

function App() {
  useEffect(() => {
    // google analytics for page changes only captured in PreProd or Production
    const env = window.__RUNTIME_CONFIG__.REACT_APP_TARGET_ENVIRONMENT;

    if (env === 'Pre' || env === 'Production') {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='App'>
      <Layout />
    </div>
  );
}

export default hot(module)(App);
