import React from 'react';
import SiteLogo from '../site/SiteLogo';
import HeaderMenuContainer from '../site/HeaderMenuContainer';
import MobileMenuContainer from '../site/MobileMenuContainer';
import BannerComponent from './BannerComponent';
import './SiteHeader.module.scss';

// NOTE: the header needs a background so that we can fix it for when a banner text is present so users still see the top menu items
function SiteHeader() {
  return (
    <>
      <header
        style={{
          padding: '0.1rem 1rem',
          position: 'sticky',
          top: '0',
          zIndex: '999',
          backgroundColor: 'white',
        }}
      >
        <nav className='navbar navbar-expand-md' id='header'>
          <SiteLogo />
          <HeaderMenuContainer />
          <MobileMenuContainer />
        </nav>
        <BannerComponent />
      </header>
    </>
  );
}

export default SiteHeader;
