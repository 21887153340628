import React from 'react';
import NavLink from '../../controls/NavLink';
import styles from './site.module.scss';
import { useFetchDataOnLoad } from 'hooks';
import { getSettings } from 'services/Settings';

const { siteMenuItems } = styles;

const SiteMenuItems = () => {
    const { result } = useFetchDataOnLoad(() => getSettings(), []);
    const settings = result;

    return (
        <>
            <ul
                className={`${siteMenuItems} navbar-nav mr-auto ml-auto`}
                id='main-nav'
            >
                <li className='nav-item'>
                    <NavLink className='nav-link text-center fw-bold'
                        hrefLink='https://www.familyrelationships.gov.au/find-local-help'
                        openInNewTab={true}
                        displayText='Find a Government funded FDR service provider'
                    />
                </li>
                <li>
                    <NavLink
                        className='nav-link text-center'
                        hrefLink='/search'
                        openInNewTab={false}
                        displayText='Find a practitioner'
                        dataTestId='SiteMenuFindPractitionerLink'
                    />
                </li>
                <li>
                    <NavLink
                        className='nav-link text-center'
                        hrefLink='/sign-in'
                        openInNewTab={false}
                        displayText='Apply to become a practitioner'
                        dataTestId='SiteMenuApplyToBecomePractitionerLink'
                    />
                </li>
            </ul>
            <ul className={`${siteMenuItems} navbar-nav`}>
                <li>
                    <NavLink
                        className='nav-link'
                        hrefLink={settings.portalUrl}
                        openInNewTab={true}
                        displayText='Sign in to your FDR account'
                    />
                </li>
            </ul>
        </>
    );
};

export default SiteMenuItems;
