import React from 'react';
import SiteMenuItems from './SiteMenuItems';

// Hosts the Site menu items shown for Medium/Large views
const HeaderMenuContainer = () => {
    return (
        <>
            <div className='collapse navbar-collapse' id='main-menu'>
                <SiteMenuItems />
            </div>
        </>
    );
};

export default HeaderMenuContainer;
