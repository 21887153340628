import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// google analytics for page changes only captured in PreProd or Production
const env = window.__RUNTIME_CONFIG__.REACT_APP_TARGET_ENVIRONMENT;
if (env === 'Pre' || env === 'Production') {
  const trackingId = window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_ANALYTICS_KEY;
  ReactGA.initialize(trackingId);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
