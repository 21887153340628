import React, { useState } from 'react';
import SiteMenuListItems from './SiteMenuItems';
import { hamburgerIcon } from '../../utils/Icons';
import styles from './site.module.scss';

const { mobileSiteMenu } = styles;

// Hosts the Hamburger and Wizard Menu when in mobile or small screen view
const MobileMenuContainer = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const HamburgerClicked = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  const showSiteMenu = showMobileMenu;

  return (
    <>
      <div className='navbar-toggler'>
        <button
          className='navbar-toggler-button'
          type='button'
          data-toggle='collapse'
          data-target='#main-menu'
          aria-controls='main-menu'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={HamburgerClicked}
        >
          {hamburgerIcon()}
        </button>
      </div>
      {showSiteMenu && (
        <div className={mobileSiteMenu}>
          <SiteMenuListItems />
        </div>
      )}
    </>
  );
};

export default MobileMenuContainer;
