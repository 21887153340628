import React from 'react';

const SignIn = React.lazy(() => import('./pages/SignIn/SignIn'));
const Search = React.lazy(() => import('./pages/Search/Search'));

const routes = [
  { path: '/', exact: true, name: 'Home', component: Search },
  {
    path: '/search',
    exact: true,
    name: 'Search for a practitioner',
    component: Search,
  },
  { path: '/sign-in', exact: true, name: 'Sign in', component: SignIn },
];

export default routes;
