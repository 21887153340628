import React from 'react';
import SiteHeader from '../../layout/header/SiteHeader';
import SiteFooter from '../../layout/footer/SiteFooter';
import Content from './Content';
import { BrowserRouter as Router } from 'react-router-dom';

const Layout = () => {
  return (
    <div className='c-app c-default-layout'>
      <Router>
        <div className='c-wrapper'>
          <SiteHeader />
          <div className='c-body'>
            <Content />
          </div>
          <SiteFooter />
        </div>
      </Router>
    </div>
  );
};

export default Layout;
