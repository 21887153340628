import { useState, useEffect, useMemo } from 'react';

export function useFetchDataOnLoad(promiseFunc, defaultVal) {
  const [fetching, setFetching] = useState(true);
  const [result, setResult] = useState(defaultVal);

  const promise = useMemo(promiseFunc, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getData = async () => {
      return promise
        .then((result) => {
          setResult(result);
        })
        .finally(() => {
          setFetching(false);
        });
    };
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = async () => {
    setFetching(true);
    promiseFunc() // un-memoized
      .then((result) => {
        setResult(result);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  return {
    fetching,
    result,
    setResult,
    refresh,
  };
}
