import React, { useEffect, useState } from 'react';
import { IBannerText } from '../../services/flairDatatypes';
import ReferenceDataApi from '../../services/ReferenceData';
import { BannerTextType } from '../../utils/Enums';
import styles from './SiteHeader.module.scss';

const { bannerText } = styles;

const BannerComponent = () => {
  const referenceApi = new ReferenceDataApi();

  const [isLoading, setIsLoading] = useState(true);
  const [messageRegister, setMessageRegister] = useState('');

  // initial load of any existing data - Banner list
  useEffect(() => {
    setIsLoading(true);
    // reference data load
    referenceApi
      .getAllBannerTexts()
      .then((response: any) => {
        const bannerTexts = response as IBannerText[];
        if (bannerTexts && bannerTexts.length > 0) {
          bannerTexts.forEach((item: IBannerText) => {
            if (item.bannerDefaultId === BannerTextType.RegisterPortalBanner) {
              setMessageRegister(item.bannerText);
              setIsLoading(false);
            }
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // determine whether a banner exists and show it if exists
  if (isLoading === false && messageRegister && messageRegister.length > 0) {
    return <div className={`${bannerText}`}>{messageRegister}</div>;
  }
  return null;
};

export default BannerComponent;
