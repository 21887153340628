import React from 'react';
import { Link } from 'react-router-dom';
import { validateAndSecureURL } from 'utils/Common';

interface NavLinkProps {
  hrefLink: string;
  displayText: string;
  className?: string;
  openInNewTab?: boolean;
  handleOnClick?: any;
  dataTestId?: string;
}

const NavLink = (props: NavLinkProps) => {
  const {
    hrefLink,
    displayText,
    className,
    openInNewTab,
    handleOnClick,
    dataTestId,
  } = props;

  if (openInNewTab) {
    return (
      <a
        className={className}
        href={validateAndSecureURL(hrefLink)}
        target='_blank'
        rel='noreferrer'
        data-testid={dataTestId}
        onClick={handleOnClick}
      >
        {displayText}
      </a>
    );
  }

  return (
    <Link
      className={className}
      to={validateAndSecureURL(hrefLink)}
      data-testid={dataTestId}
    >
      {displayText}
    </Link>
  );
};

export default NavLink;
