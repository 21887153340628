import * as dayjs from 'dayjs';
import { Tracker } from 'react-tracker';

const tracker = new Tracker();

const logger = {
  init: () => {
    // Listen on all events
    tracker.on('*', (event) => {
      console.log(event);
      // trigger log event to server.
    });
  },
};

export const log = (type, payload = null) => {
  const timestamp = dayjs().toISOString();

  tracker.trackEvent({
    type,
    payload,
    timestamp,
  });
};

export default logger;
