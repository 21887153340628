import axios from 'axios';
import { timeout } from 'utils/timeout';
import { errorToast } from 'utils/toast';
import { log } from 'utils/logger';
import { toast } from 'react-toastify';

const API_BASE_URL = '/api/';

const instance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    log('error', { error });

    if (error?.response?.status === 401) {
      errorToast('Access denied.');
      return Promise.reject(error);
    }

    // global error handler
    if (error?.response?.data?.detail) {
      try {
        return Promise.reject(JSON.parse(error.response.data.detail));
      } catch (e) {
        return Promise.reject(error.response.data.detail);
      }
    }

    // catch all

    const NETWORK_ERROR_TOAST_ID = 'network-error-toast';
    if (!toast.isActive(NETWORK_ERROR_TOAST_ID)) {
      errorToast(
        'Sorry, we cannot connect to the services now, please try again later.',
        false,
        {
          toastId: NETWORK_ERROR_TOAST_ID,
        }
      );
    }

    return Promise.reject(error);
  }
);

const api = {
  ...instance,
  dummy: async (returnData, customDelay = 1000) => {
    console.clear();
    console.error(`
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    This API call is currently stubbed, please replace with real API call.
    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    `);
    await timeout(customDelay);
    return returnData ? returnData : { success: true };
  },
};

export default api;
